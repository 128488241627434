import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink, UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
import {  TableRow, TableCell, TableBody, Table, Label} from 'semantic-ui-react';
import { isInternalURL } from '@plone/volto/helpers/Url/Url';


const DefaultTemplate = ({ items, linkTitle, linkHref, isEditMode }) => {
  let link = null;
  let href = linkHref?.[0]?.['@id'] || '';

  // return some pretty file sizes
  function formatBytes(bytes,decimals) {
    if(bytes == 0) return '0 Bytes';
    var k = 1024,
        dm = decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  // switch check for filetype before determining whether to return filesize
  function getFileStats(item){
    switch(item['@type'].toLowerCase()) {
      case 'image':
        return (<span className="file_details"> 
            &nbsp;- Size:  {formatBytes(item['image']['size'])}
            &nbsp;- Resolution: {item['image']['width']} x {item['image']['height']}
          </span>);
        break;
      case 'file':
        return (<span className="file_details">&nbsp;- Size:  {formatBytes(item['file']['size'])} </span>);
      }
    return null;
  }

  if (isInternalURL(href)) {
    link = (
      <ConditionalLink to={flattenToAppURL(href)} condition={!isEditMode}>
        {linkTitle || href}
      </ConditionalLink>
    );
  } else if (href) {
    link = <UniversalLink href={href}>{linkTitle || href}</UniversalLink>;
  }

  return (
    <>
      <div className="items">
      <Table celled fixed striped>
            <TableBody>
        {items.map((item) => (
          <TableRow key={item['@id']}>
                <TableCell>
                    <ConditionalLink item={item} condition={!isEditMode}>
                        {item['@type'].toLowerCase() === 'document' ? <i className="ri-file-text-line ficon"></i>:''}
                        {item['@type'].toLowerCase() === 'link' ? <i className="ri-links-line ficon"></i>:''}
                        {item['@type'].toLowerCase() === 'news item' ? <i className="ri-news-line ficon"></i>:''}
                        {item['@type'].toLowerCase() === 'image' ? <i className="ri-file-image-line ficon"></i>:''}
                        {item['@type'].toLowerCase() === 'file' ? <i className="ri-file-line ficon"></i>:''}
                        {item['@type'].toLowerCase() === 'event' ? <i className="ri-calendar-2-line ficon"></i>:''}
                        {item['@type'].toLowerCase() === 'folder' ? <i className="ri-folder-line ficon"></i>:''}
                    {item.title ? item.title : item.id}
                    </ConditionalLink>
                    {getFileStats(item)}
                    <p className='byline'>∟ by:
                      {item.adv_creators[0]['email'] ? 
                        <a className="mail_link" href={"mailto:" + item.adv_creators[0]['email']}>
                      <i className="ri-mail-line"></i> 
                        {item.adv_creators[0]["fullname"] ?? item.creators[0]} </a> : 
                        <>&nbsp; {item.adv_creators[0]["fullname"]}</>}
                        - Last Modified: {new Date(item.modified).toLocaleDateString("en-CA")}
                    </p>
                    {item['description'] ? (<><Label>Description</Label>
                    <p className='listing_summary'>
                      {item['description']}
                    </p></>):null}

                </TableCell>
            </TableRow>
        ))}
            </TableBody>
        </Table>
      </div>

      {link && <div className="footer">{link}</div>}
    </>
  );
};
DefaultTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};
export default DefaultTemplate;
