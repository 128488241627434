import React from 'react';
import PropTypes from 'prop-types';

import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';

import DefaultImageSVG from '@plone/volto/components/manage/Blocks/Listing/default-image.svg';

/**
 * Renders a preview image for a catalog brain result item.
 *
 */
function getPreviewImage(item){
  if (item.preview_image){
    return item.preview_image.download
  }else if (item.image){
    return item.image.scales.preview.download
  }
  return DefaultImageSVG
}


function PreviewImage(props) {
  const { item, size = 'preview', alt, ...rest } = props;
  console.log(item);
  // const src = item.preview_image ? item.preview_image.download
  //   : config.getComponent({
  //       name: 'DefaultImage',
  //       dependencies: ['listing', 'summary'],
  //     }).component || DefaultImageSVG;
  const src = getPreviewImage(item);

  return <img className="preview_image" src={src} alt={alt ?? item.title} {...rest} />;
}

PreviewImage.propTypes = {
  size: PropTypes.string,
  item: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    image_field: PropTypes.string,
    title: PropTypes.string.isRequired,
  }),
};

export default PreviewImage;
