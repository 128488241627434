import { userAgreementReducer } from './reducers'

export { default as CookieBanner } from './UserAgreementContainer'
export { GET_DATA_POLICY_CONSENT_INFOS, getDataPolicyConsentInfo } from './actions'

export default config => {
  config.addonReducers = {
    ...config.addonReducers,
    userAgreementInfos: userAgreementReducer,
  }

  return config
}
