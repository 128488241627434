/**
 * Cookie banner text actions.
 * @module actions/getCookieBannerText
 */
export const GET_DATA_POLICY_CONSENT_INFOS = 'GET_DATA_POLICY_CONSENT_INFOS';
export const AGREE_TO_DATA_POLICY = 'AGREE_TO_DATA_POLICY';
export const NOT_YET_DATA_POLICY = 'NOT_YET_DATA_POLICY';


/**
 * Get cookie consent infos.
 * @function getDataPolicyConsentInfo
 * @returns {Object} Get cookie consent infos action.
 */
export function getDataPolicyConsentInfo() {
  return {
    type: GET_DATA_POLICY_CONSENT_INFOS,
    request: {
      op: 'get',
      path: `/@datapolicyconsent-infos`,
    },
  }
}
export function agreeToDataPolicy() {
  return {
    type: AGREE_TO_DATA_POLICY,
    request: {
      op: 'get',
      path: `/@agreetodatapolicy`,
    },
  }
}
export function agreeNotYet() {
  return {
    type: NOT_YET_DATA_POLICY,
    request: {
      op: 'get',
      path: `/@agreenotyet`,
    },
  }
}